import * as React from "react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import { useKeenSlider } from "keen-slider/react"
import NextLink from "next/link"
import "keen-slider/keen-slider.min.css"

const testimonialSlides = [
  {
    quote: "'Dat ik mensen blij kan maken met wat mij blij maakt, feels unreal! Dankzij Kunstbende!'",
    title: "Senn (15) - DJ",
    image: "Senn.jpeg",
  },
  {
    quote: "'Het leukste aan Kunstbende was het leren kennen van andere creative zielen'",
    title: "Sikandar  (19) - Filmer",
    image: "Sikandar.jpeg",
  },
  {
    quote: "'Door mee te doen aan Kunstbende heb ik meer zelfvertrouwen opgebouwd en goede vrienden ontmoet'",
    title: "Fenne (12) - Danser",
    image: "Fenne.jpg",
  },
]

export function JoinTheKlub() {
  const color = useColorModeValue("gray.700", "gray.400")
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const displayCount = 1
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: displayCount,
    mode: "snap",
    spacing: 15,
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })

  return (
    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: 20, md: 10 }} justifyContent="center">
      <Stack spacing={10} justifyContent="center">
        <Text fontSize={{ base: 32, md: 48 }} fontWeight={900} lineHeight="shorter" role="heading">
          Join the{" "}
          <Text as="span" color="yellow.500" role="heading">
            Klub
          </Text>
        </Text>
        <Text color={color} fontSize={{ base: "lg", md: "2xl" }} lineHeight="short" role="heading">
          Kunstbende is de plek waar je jezelf laat zien, jezelf ontdekt, andere jonge makers ontmoet, van
          elkaar leert, samenwerkt, valt en weer opstaat, dealt met kritiek en groeit!
        </Text>
        <Button
          as={NextLink}
          passHref
          href="/inschrijven"
          fontSize={{ base: 16, md: 22 }}
          px={{ base: 8, md: 8 }}
          py={{ base: 6, md: 8 }}
          colorScheme="yellow"
          w="fit-content"
        >
          JOIN
        </Button>
      </Stack>

      <Stack spacing={0} justify="center" pl={{ base: 0, md: 10 }}>
        <Box className="keen-slider" ref={sliderRef} py={2}>
          {testimonialSlides.map(({ quote, title, image }, index) => (
            <Stack
              key={index}
              spacing={8}
              className="keen-slider__slide"
              overflow="visible"
              alignItems="center"
            >
              <Image
                alt={"clubdia " + index}
                src={image}
                width={200}
                height={200}
                objectFit="cover"
                alignSelf="center"
                borderRadius="50%"
                boxShadow="0px 9.5px 40px rgba(0, 0, 0, 0.3)"
              />
              <Stack w="70%" pb={6}>
                <Text color={color} fontSize={20} lineHeight="normal" textAlign="center" role="heading">
                  {quote}
                </Text>
                <Text color={color} fontSize={20} lineHeight="normal" textAlign="center" role="heading">
                  {title}
                </Text>
              </Stack>
            </Stack>
          ))}
        </Box>
        {slider && testimonialSlides.length > displayCount && (
          <Flex justify="center">
            <IconButton
              colorScheme="gray"
              variant="ghost"
              aria-label="vorige dia"
              icon={<MdKeyboardArrowLeft size={48} />}
              onClick={(e) => {
                e.stopPropagation()
                slider.prev()
              }}
              disabled={currentSlide === 0}
              width="3rem"
              mr={5}
            />
            <HStack alignItems="center" spacing={5}>
              {Array.from({ length: slider.details().size }).map((_, i) => {
                const isActive = currentSlide === i
                return (
                  <Button
                    key={i}
                    size="xs"
                    w="18px"
                    h="18px"
                    minH="18px"
                    minW="18px"
                    p={0}
                    onClick={() => slider.moveToSlideRelative(i)}
                    borderRadius="50%"
                    bgColor={isActive ? "pink.500" : "gray.300"}
                    aria-label={`getuigenisdia ${i + 1}`}
                  />
                )
              })}
            </HStack>
            <IconButton
              colorScheme="gray"
              variant="ghost"
              aria-label="volgende dia"
              icon={<MdKeyboardArrowRight size={48} />}
              onClick={(e) => {
                e.stopPropagation()
                slider.next()
              }}
              disabled={currentSlide === slider.details().size - 1}
              width="3rem"
              ml={5}
            />
          </Flex>
        )}
      </Stack>
    </SimpleGrid>
  )
}
