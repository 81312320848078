import * as React from "react"
import { gql } from "@apollo/client"
import { Flex, Stack, Text } from "@chakra-ui/react"

import { LatestProjectItemFragment } from "lib/graphql"

import { LatestProjectsSlider } from "./LatestProjectsSlider"
import { LinkButton } from "./LinkButton"
import { FaArrowRight } from "react-icons/fa"

const _ = gql`
  fragment LatestProjectItem on Project {
    id
    title
    image
    url
    creator {
      id
    }
    comments {
      count
    }
  }
`

interface Props {
  projects: LatestProjectItemFragment[]
}

export function FeedLatestProjects({ projects }: Props) {
  if (projects.length === 0) return null
  return (
    <Stack spacing={4}>
      <Text fontSize={{ base: "lg", md: "2xl" }} color="yellow.500" fontWeight="black">
        Trending projecten voor jou
      </Text>
      <LatestProjectsSlider projects={projects} projectsCount={projects.length} />
      <Flex justify="flex-end">
        <LinkButton href="/projecten" variant="link" rightIcon={<FaArrowRight />} textDecoration="underline">
          Bekijk alle projecten
        </LinkButton>
      </Flex>
    </Stack>
  )
}
