import * as React from "react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { Box, Button, Flex, HStack, IconButton, Stack, useBreakpointValue } from "@chakra-ui/react"
import { useKeenSlider } from "keen-slider/react"

import { LandingPageProjectItemFragment } from "lib/graphql"

import { ProjectTile } from "./ProjectTile"

interface Props {
  projects: LandingPageProjectItemFragment[]
  projectsCount: number
}

export function ProjectSlider({ projects, projectsCount }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const displayCount = isMobile ? 1 : 3

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: displayCount,
    mode: "snap",
    spacing: 4,
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentIndex(s.details().relativeSlide)
    },
  })

  React.useEffect(() => {
    setCurrentIndex(0)
  }, [isMobile])

  return (
    <>
      <Box id="my-keen-slider-project" className="keen-slider" ref={sliderRef} position="relative">
        {projects.map((project) => (
          <Stack key={project.id} cursor="pointer" px={2} className="keen-slider__slide">
            <ProjectTile key={project.id} project={project} />
          </Stack>
        ))}
      </Box>
      {projectsCount > displayCount && (
        <Flex justify="center" pt={0} mt={4}>
          <IconButton
            colorScheme="gray"
            variant="ghost"
            aria-label="vorige dia"
            icon={<MdKeyboardArrowLeft size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.prev()
            }}
            disabled={currentIndex === 0}
            width="3rem"
            mr={5}
          />
          <HStack alignItems="center" spacing={6}>
            {Array.from({
              length:
                projectsCount % displayCount > 0 && projectsCount === 9
                  ? projectsCount / displayCount + 1
                  : projectsCount / displayCount,
            }).map((_, i) => {
              const isActive = currentIndex === i * displayCount
              return (
                <Button
                  key={i}
                  size="xs"
                  w="18px"
                  h="18px"
                  minH="18px"
                  minW="18px"
                  p={0}
                  onClick={(e) => {
                    e.stopPropagation()
                    slider.moveToSlide(i * displayCount)
                  }}
                  borderRadius="50%"
                  bgColor={isActive ? "pink.500" : "gray.300"}
                  aria-label={`projectdia ${i + 1}`}
                />
              )
            })}
          </HStack>
          <IconButton
            colorScheme="gray"
            variant="ghost"
            aria-label="volgende dia"
            icon={<MdKeyboardArrowRight size={48} />}
            onClick={(e) => {
              e.stopPropagation()
              slider.next()
            }}
            disabled={currentIndex + 1 === projectsCount || currentIndex >= projectsCount}
            width="3rem"
            ml={5}
          />
        </Flex>
      )}
    </>
  )
}
